import * as React from "react";
import { Col, Row } from "reactstrap";
import RxBlurb from "../../models/RxBlurb";
import RxBlurbBlock from "./rxBlurbBlock";

const blurbs: RxBlurb[] = [
    {
        title: "Experience",
        bullets: [
            "Hundreds of Pharmacy Benefit Management (PBM) audits conducted, including claim pricing, benefit implementation, pharmaceutical manufacturer rebate audits and Medicare Part D regulation compliance.",
            "All team members have extensive PBM backgrounds.",
            "Unsurpassed experience analyzing and investigating pharmacy claims data."
        ]
    },
    {
        title: "Integrity",
        bullets: [
            "Unbiased audit approach produces valid and recoverable findings.",
            "No conflicting consulting services are offered, in compliance with Sarbanes-Oxley auditor independence standards.",
            "No fees based on contingencies or percent of recoveries."
        ]
    },
    {
        title: "Results",
        bullets: [
            "Accurate and fair assessment of PBM performance and a plan to correct any deficiencies.",
            "Efficient audit process leads to shortened audit timeframe and produces results.",
            "Advanced technological resources ensuring data security, confidentiality of PHI and compliance with HITECH standards."
        ]
    }
];

const RxBlurbs: React.FunctionComponent = () => (
    <Row>
        {blurbs.map(blurb => (
            <Col key={`${blurb.title}-blurb`} lg={4}>
                <RxBlurbBlock blurb={blurb} />
            </Col>
        ))}
    </Row>
);

export default RxBlurbs;
