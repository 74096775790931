import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import * as React from "react";
import { Col, Row } from "reactstrap";
import ContentSection from "../../components/contentSection";
import Layout from "../../components/layout";
import PageTitle from "../../components/pageTitle";
import SEO from "../../components/seo";
import RxBlurbs from "../../components/services/rxBlurbs";
import headerRx from "../../images/header-rx.jpg";
import rxKeyboard from "../../images/rx-keyboard.jpg";
import pharmacyBenefits from "../../images/pharmacy-benefits.png";
import headshot from "../../images/f-randy-vogenberg.png";

const pharmBenefitsHref =
    "http://www.ifebp.org/Bookstore/pages/PharmBenefits.aspx";

class Rx extends React.Component {
    render() {
        return (
            <Layout>
                <SEO title="Tiber Creek Rx" />

                <PageTitle title="Tiber Creek Rx" imageUrl={headerRx} />

                <ContentSection title="Leading Technology">
                    <p className="mb-4">
                        Tiber Creek Rx has leveraged our information technology
                        resources with our superior industry expertise to create
                        efficiencies in the audit process, shortening the length
                        of time needed to complete an audit and get results. We
                        can manage data sets of any size and format, and our
                        audit process includes a review of every unique claim
                        record. Tiber Creek Rx’s audit services fulfill all
                        Medicare plan sponsor audit obligations, and we comply
                        with all HIPAA requirements, including the newest HITECH
                        standards, to ensure the confidentiality of Protected
                        Health Information.
                    </p>
                </ContentSection>

                <ContentSection narrow>
                    <RxBlurbs />
                </ContentSection>

                <ContentSection narrow>
                    <Row>
                        <Col lg={6} xl={8}>
                            <h3>
                                Tiber Creek Rx specializes in auditing PBMs for
                                clients of all sizes and types.
                            </h3>

                            <p>
                                Our team has extensive experience working with:
                            </p>
                            <ul style={{ columns: 2 }}>
                                <li>Independent health plans</li>
                                <li>Third-Party Administrators (TPAs)</li>
                                <li>Workers compensation plans</li>
                                <li>
                                    Medicaid Advantage, Part D, and SNP plans
                                </li>
                                <li>BCBS plans</li>
                                <li>Coalitions</li>
                                <li>Employer groups</li>
                                <li>Managed Medicaid plans</li>
                                <li>Labor unions/Taft-Hartley funds</li>
                                <li>Retirement funds</li>
                            </ul>
                        </Col>

                        <Col lg={6} xl={4}>
                            <img
                                src={rxKeyboard}
                                alt="Rx key on a keyboard"
                                className="img-fluid"
                            />
                        </Col>
                    </Row>
                </ContentSection>

                <ContentSection title="Unbiased Philosophy" narrow>
                    <p>
                        Tiber Creek Rx approaches the audit process from an
                        unbiased perspective. Sarbanes-Oxley auditor
                        independence standards restrict auditing firms from
                        providing non-audit consulting services for the same
                        clients. Tiber Creek Rx adheres to these standards as we
                        do not provide conflicting consulting services, such as
                        PBM sourcing (RFPs for PBM services). Many consulting
                        firms offering PBM sourcing services are paid fees by
                        the PBMs their clients select. We do not need to
                        generate additional business for a consulting division.
                    </p>

                    <p>
                        Our objectives remain focused on quality audit results
                        rather than our compensation. Therefore, our fees are
                        never based on contingencies, eliminating any
                        unsupportable and unrecoverable findings that drag-out
                        the audit time-frame and frustrate both our clients and
                        the PBMs.
                    </p>
                </ContentSection>

                <ContentSection title="The Process" narrow>
                    <p>
                        Our audit process was developed based on the lessons
                        learned over nine years of auditing PBMs. The approach
                        is engineered to make the process clear and focused,
                        eliminating wasted time for all parties. Tiber Creek Rx
                        works with each client to develop an agreed upon audit
                        scope and time-frame. We tailor the audit to meet the
                        unique needs of each client.
                    </p>

                    <p>
                        Obtaining accurate information and data is essential to
                        producing a quality audit result. Our auditors do not
                        work in a vacuum; they engage the client and the PBM
                        throughout the process to ensure correct parameters are
                        applied and tested.
                    </p>
                </ContentSection>

                <ContentSection narrow>
                    <Row>
                        <Col lg={6}>
                            <h3>PBM Compliance Auditing</h3>

                            <p>Tiber Creek Rx’s audit services include:</p>

                            <ul>
                                <li>PBM Contract Pricing Audits</li>
                                <li>
                                    Audit of PBM Benefit Design Implementation
                                </li>
                                <li>
                                    Pharmaceutical Manufacturer Rebate Audits
                                </li>
                                <li>
                                    Medicare Part C and D Plan PBM Contract
                                    Compliance Audits
                                </li>
                                <li>
                                    Audit of Medicare Sponsor Requirements
                                    Delegated to the PBM
                                </li>
                                <li>Retiree Drug Subsidy Program Audits</li>
                            </ul>
                        </Col>

                        <Col lg={6}>
                            <h3>Pharmacy Claim Analytics</h3>

                            <p>
                                Tiber Creek Rx’s pharmacy claim analytics
                                services include:
                            </p>

                            <ul>
                                <li>Worksite Pharmacy Support Services</li>
                                <li>
                                    Medicare Formulary Compliance Reviews
                                    including Claim Reject Verification
                                </li>
                                <li>Fraud, Waste and Abuse Monitoring</li>
                                <li>Pricing and Benefit Design Modeling</li>
                                <li>Clinical Data Mining</li>
                                <li>340B Pricing Verification</li>
                            </ul>
                        </Col>
                    </Row>
                </ContentSection>

                <ContentSection narrow>
                    <h2>The Case for Auditing Your PBM</h2>

                    <p>
                        One small, honest mistake in implementing pharmacy
                        benefits can quickly lead to your plan and members
                        incurring substantial overcharges. Click to read our
                        white paper on why you should audit your PBM.
                    </p>
                </ContentSection>

                <ContentSection title="Contact Tiber Creek Consulting">
                    <p className="mb-4">
                        Contact Tiber Creek Consulting for a complimentary
                        assessment of your existing infrastructure, and discover
                        how we can help deploy solutions. Please inquire about
                        other pharmacy program analytical services. Tiber creek
                        Rx can help you in all areas where you need pharmacy
                        claims data expertise.
                    </p>

                    <div className="text-center">
                        <Link
                            to="/contact"
                            className="btn btn-lg btn-outline-dark"
                        >
                            Learn More with a Free Consultation
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                className="ml-3"
                            />
                        </Link>
                    </div>
                </ContentSection>

                <ContentSection>
                    <h2 className="mb-4">Industry Publications</h2>

                    <Row>
                        <Col lg={6} xl={4}>
                            <h3>Pharmacy Benefits</h3>

                            <a href={pharmBenefitsHref}>
                                <img
                                    src={pharmacyBenefits}
                                    alt="Pharmacy Benefits publication"
                                    className="img-fluid"
                                />
                            </a>
                        </Col>

                        <Col lg={4} xl={8}>
                            <a href={pharmBenefitsHref}>
                                <h4>
                                    Pharmacy Benefits: Plan Design and
                                    Management
                                </h4>
                            </a>

                            <div className="h5">by F. Randy Vogenberg</div>

                            <p>
                                Pharmacy Benefits: Plan Design and Management is
                                a must-have guide for benefits professionals and
                                trustees responsible for a prescription pharmacy
                                or medical benefits program. Addresses benefit
                                plan design, the selection of a pharmacy
                                benefits manager and pharmacy network,
                                compliance, specialty pharmacy, cost-saving
                                strategies and more.
                            </p>

                            <p>
                                Pharmaceuticals have become an integral
                                component of medical treatment, with some
                                medications completely transforming health care
                                outcomes and delivery. When was the last time
                                you examined the prescription drug component of
                                your health care benefits?
                            </p>

                            <h5>About the Author</h5>

                            <div className="mb-4">
                                <img
                                    src={headshot}
                                    alt="F. Randy Vogenberg headshot"
                                    className="float-left m-3"
                                />
                                <p>
                                    F. Randy Vogenberg, R.Ph., Ph.D., is a
                                    principal with the Institute for Integrated
                                    Healthcare in Sharon, Massachusetts, and a
                                    former national health benefits thought
                                    leader with Aon Consulting. Since 1981, he
                                    has consulted on economic issues for
                                    pharmaceutical services, drug benefit
                                    strategy and decision making for drug
                                    therapy selection with government and
                                    employer clients.
                                </p>
                            </div>

                            <div className="text-center">
                                <a
                                    href={pharmBenefitsHref}
                                    className="btn btn-lg btn-outline-dark"
                                >
                                    Order Now!
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        className="ml-3"
                                    />
                                </a>
                            </div>
                        </Col>
                    </Row>
                </ContentSection>
            </Layout>
        );
    }
}

export default Rx;
