import * as React from "react";

interface PageTitleProps {
    title: string;
    imageUrl: string;
}

const PageTitle: React.FunctionComponent<PageTitleProps> = ({
    title,
    imageUrl
}) => (
    <div
        className="d-flex flex-column justify-content-center align-items-center text-center bg-secondary text-white"
        style={{
            minHeight: "40vh",
            backgroundImage: `url(${imageUrl})`,
            backgroundPosition: "center",
            backgroundSize: "cover"
        }}
    >
        <h1
            className="display-4"
            style={{
                textShadow: "0px 0px 8px rgba(0, 0, 0, 0.5)"
            }}
        >
            {title}
        </h1>
    </div>
);

export default PageTitle;
