import * as React from "react";
import RxBlurb from "../../models/RxBlurb";

interface RxBlurbBlockProps {
    blurb: RxBlurb;
}

const RxBlurbBlock: React.FunctionComponent<RxBlurbBlockProps> = ({
    blurb: { title, bullets }
}) => (
    <React.Fragment>
        <h3 className="text-center mb-3">{title}</h3>

        <ul>
            {bullets.map((bullet, i) => (
                <li key={`${title}-bullet-${i}`}>{bullet}</li>
            ))}
        </ul>
    </React.Fragment>
);

export default RxBlurbBlock;
